import { FC, useCallback, useEffect, useState } from 'react';
import { Sheet } from '@/app/_components/Sheet';
import { ELButton, ELLoader } from '@davincihealthcare/elty-design-system-react';
import { useIsScreen } from '@/app/_hooks/useIsScreen';
import Modal from '@/app/_components/modal';
import { ArrowRightIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { RecapSection } from '@/app/_components/widgets/listing/cardV2/RecapSection';
import { useAvailabilitySlots } from '@/app/_components/widgets/listing/_hooks/useAvailabilitySlots';
import { getStartOfDayTime } from '@/app/_components/widgets/listing/_utils/time';
import { SlotAvailability } from '@/app/_components/widgets/listing/types';

interface AvailabilityModalProps {
  specialityId: string;
  doctorId: string;
  workgroupId: string;
  onClose: () => void;
  onSubmit: (selectedSlot: SlotAvailability) => void;
  initialSelectedDay: number | 'OTHER' | null;
}

const getDayTitle = (timestamp: number) => {
  const todayMs = getStartOfDayTime(Date.now());
  const tomorrowMs = getStartOfDayTime(Date.now(), 1);
  const date = new Date(timestamp);

  const isToday = timestamp === todayMs;
  const isTomorrow = timestamp === tomorrowMs;

  if (isToday) {
    return `Oggi ${date.toLocaleDateString('it', { day: 'numeric', month: 'long' })}`;
  }
  if (isTomorrow) {
    return `Domani ${date.toLocaleDateString('it', { day: 'numeric', month: 'long' })}`;
  }
  return date.toLocaleDateString('it', { weekday: 'long', day: 'numeric', month: 'long' });
};

export const AvailabilityModal: FC<AvailabilityModalProps> = ({
  specialityId,
  doctorId,
  workgroupId,
  onClose,
  onSubmit,
  initialSelectedDay,
}) => {
  const [selectedSlot, setSelectedSlot] = useState<SlotAvailability | null>(null);
  const [hasBeenInitialized, setHasBeenInitialized] = useState(false);

  const { groupedDays, sortedDates, isInitialLoading, fetchNextPage, isLoadingMore, hasMoreData } = useAvailabilitySlots({
    specialityId,
    doctorId,
    workgroupId,
  });

  const isMobile = useIsScreen('md');

  /*
    Wait for the first load to complete
    Scroll to the selected day or the first available slot if initialSelectedDay is an existing day
    Scroll to the "show more times" button if initialSelectedDay is OTHER and there are slots available, fetch also additional slots
  */
  useEffect(() => {
    // If the component has been initialized or there are no slots, do nothing
    if (hasBeenInitialized || sortedDates.length === 0) return;
    // If the initial selected day is OTHER and there are slots, scroll to the "show more times" button and fetch additional slots
    if (initialSelectedDay === 'OTHER' && sortedDates.length > 0) {
      document.getElementById('show-more-times')?.scrollIntoView({ behavior: 'smooth' });
      fetchNextPage();
    } else if (initialSelectedDay && groupedDays[initialSelectedDay]) {
      const firstSlot = groupedDays[initialSelectedDay][0];
      if (firstSlot) {
        setSelectedSlot(firstSlot);
        document.getElementById(`day-${initialSelectedDay}`)?.scrollIntoView({ behavior: 'smooth' });
      }
    }
    setHasBeenInitialized(true);
  }, [initialSelectedDay, groupedDays, sortedDates.length, hasBeenInitialized, fetchNextPage]);

  const handleLoadMore = useCallback(async () => {
    await fetchNextPage();
  }, [fetchNextPage]);

  const handleSubmit = useCallback(() => {
    if (!selectedSlot) return;
    onSubmit(selectedSlot);
    onClose();
  }, [selectedSlot, onSubmit, onClose]);

  const continueButton = (
    <ELButton
      aria-label="Continua"
      label="Continua"
      variant="filled"
      color="primary"
      trailingIcon={<ArrowRightIcon className="size-4" />}
      size="large"
      disabled={!selectedSlot}
      onClick={handleSubmit}
    />
  );

  const dayList = isInitialLoading ? (
    <div className="flex h-full w-full items-center justify-center">
      <ELLoader />
    </div>
  ) : (
    <div className="flex flex-col gap-6">
      {sortedDates.map(timestamp => {
        const slots = groupedDays[timestamp];

        return (
          <div key={timestamp} id={`day-${timestamp}`} className="flex flex-col gap-3">
            <h3 className="font-semibold capitalize">{getDayTitle(timestamp)}</h3>
            <div className="grid grid-cols-[repeat(auto-fill,minmax(70px,1fr))] gap-2">
              {slots.map(slot => (
                <ELButton
                  key={slot.start}
                  data-cy={`slot-button`}
                  data-timestamp={slot.start}
                  aria-label={new Date(slot.start).toLocaleTimeString('it', { hour: '2-digit', minute: '2-digit' })}
                  onClick={() => setSelectedSlot(slot)}
                  variant="outlined"
                  size="small"
                  color={selectedSlot?.start === slot.start ? 'secondary' : 'primary'}
                  label={new Date(slot.start).toLocaleTimeString('it', { hour: '2-digit', minute: '2-digit' })}
                />
              ))}
            </div>
          </div>
        );
      })}
      <ELButton
        id="show-more-times"
        aria-label={hasMoreData ? 'Mostra altri orari' : 'Nessun altro orario disponibile'}
        label={hasMoreData ? 'Mostra altri orari' : 'Nessun altro orario disponibile'}
        variant="text"
        disabled={isInitialLoading || isLoadingMore || !hasMoreData}
        color="primary"
        size="small"
        onClick={handleLoadMore}
      />
      {isLoadingMore && <ELLoader />}
    </div>
  );

  if (isMobile) {
    return (
      <Modal
        isModalOpen={true}
        onClose={onClose}
        className="relative m-0 flex h-full max-h-full w-full max-w-full flex-col gap-6 overflow-y-auto px-4 pb-28 pt-5"
      >
        <div className="flex justify-end text-right ">
          <button aria-label="Chiudi" onClick={onClose}>
            <XMarkIcon className="size-6" />
          </button>
        </div>

        <h2 className="text-center text-2xl font-semibold text-primary">La tua selezione</h2>
        <RecapSection selectedSlot={selectedSlot} />
        {dayList}
        <div className="fixed bottom-12 left-0 flex w-full justify-center px-4">{continueButton}</div>
      </Modal>
    );
  }

  return (
    <Sheet isOpen={true} onClose={onClose}>
      <div className="flex flex-col gap-6 px-20 py-16">
        <h2 className="text-2xl font-semibold text-primary">Scelta orario</h2>
        <RecapSection selectedSlot={selectedSlot} />
        {dayList}
        {continueButton}
      </div>
    </Sheet>
  );
};
