import { useEffect, useMemo, useState, useCallback } from 'react';
import { AvailabilitySlotsForSpecialityNoAuthDocument, AvailabilitySlotsForSpecialityNoAuthQueryResult } from '@/types-aggregatore';
import { getStartOfDayTime } from '@/app/_components/widgets/listing/_utils/time';
import client from '@/utils/apolloClient';

interface UseAvailabilitySlotsProps {
  specialityId: string;
  doctorId: string;
  workgroupId: string;
}

type AvailabilitySlots = AvailabilitySlotsForSpecialityNoAuthQueryResult['availabilitySlotsForSpecialityNoAuth'];

export const useAvailabilitySlots = ({ specialityId, doctorId, workgroupId }: UseAvailabilitySlotsProps) => {
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [allData, setAllData] = useState<AvailabilitySlots>([]);
  const [hasMoreData, setHasMoreData] = useState(true);
  const today = useMemo(() => getStartOfDayTime(Date.now()), []);

  const fetchData = useCallback(
    async (timestamp: number, initialFetch = false) => {
      console.log('fetching data for timestamp', timestamp);
      setIsLoadingMore(true);
      try {
        const startDate = timestamp;

        const { data } = await client.query({
          query: AvailabilitySlotsForSpecialityNoAuthDocument,
          variables: {
            startDate,
            specialityId,
            doctorId,
            workgroupId,
            days: 30,
          },
        });

        const hasMore = data.availabilitySlotsForSpecialityNoAuth.length > 0;
        setHasMoreData(hasMore);
        setAllData(prev => {
          return initialFetch ? [...data.availabilitySlotsForSpecialityNoAuth] : [...prev, ...data.availabilitySlotsForSpecialityNoAuth];
        });
      } catch (error) {
        console.error('Error fetching availability slots:', error);
      } finally {
        setIsLoadingMore(false);
      }
    },
    [specialityId, doctorId, workgroupId],
  );

  useEffect(() => {
    const initializeData = async () => {
      setIsInitialLoading(true);
      try {
        setAllData([]);
        await fetchData(today, true);
      } catch (error) {
        console.error('Error fetching availability slots:', error);
      } finally {
        setIsInitialLoading(false);
      }
    };
    initializeData();
  }, [specialityId, doctorId, workgroupId, fetchData, today]);

  const fetchNextPage = async () => {
    if (allData.length === 0) return;
    // Get the last slot's date and add 1 day
    const lastSlotDate = new Date(allData[allData.length - 1].start);
    lastSlotDate.setDate(lastSlotDate.getDate() + 1);
    // Fetch from the next day
    return fetchData(lastSlotDate.getTime());
  };

  const groupedDays = useMemo(() => {
    if (!allData.length) return {};

    return allData.reduce<Record<string, NonNullable<AvailabilitySlots>>>((acc, slot) => {
      if (!slot) return acc;
      const dateKey = getStartOfDayTime(slot.start);
      acc[dateKey] = acc[dateKey] || [];
      acc[dateKey].push(slot);
      return acc;
    }, {});
  }, [allData]);

  const sortedDates = useMemo(() => Object.keys(groupedDays).map(Number).sort(), [groupedDays]);

  return {
    groupedDays,
    sortedDates,
    isInitialLoading,
    isLoadingMore,
    fetchNextPage,
    hasMoreData,
  };
};
